import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuard, canManageUsersGuard, canSendMessagesGuard } from './login/login.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SchichtplanComponent } from './schichtplan/schichtplan.component';
import { DienstbuchComponent } from './dienstbuch/dienstbuch.component';
import { TagesplanComponent } from './tagesplan/tagesplan.component';
import { MeldungenComponent } from './meldungen/meldungen.component';
import { SettingsComponent } from './settings/settings.component';
import { MeldungenAdministrationComponent } from './meldungen-administration/meldungen-administration.component';
import { UserAdministrationListComponent } from './user-administration-list/user-administration-list.component';
import { UserEditAdministrationComponent } from './user-edit-administration/user-edit-administration.component';
import { UserCreateAdministrationComponent } from './user-create-administration/user-create-administration.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', canActivate: [LoginGuard], children: [
    {
      path: '', component: DashboardComponent,
    },{
      path: 'dienstbuch', component: DienstbuchComponent,
    },{
      path: 'meldungen', component: MeldungenComponent,
    },{
      path: 'schichtplan', component: SchichtplanComponent,
    },{
      path: 'tagesplan', component: TagesplanComponent,
    },{
      path: 'settings', component: SettingsComponent,
    }
  ]},
  { path: 'admin', canActivate: [LoginGuard],  children: [
    {
      path: 'newuser', canActivate: [canManageUsersGuard], component: UserCreateAdministrationComponent,
    },{
      path: 'user/:id', canActivate: [canManageUsersGuard], component: UserEditAdministrationComponent,
    },{
      path: 'user', canActivate: [canManageUsersGuard], component: UserAdministrationListComponent,
    },{
      path: 'meldungen', canActivate: [canSendMessagesGuard], component: MeldungenAdministrationComponent,
    }
  ]},
  { path: '**', component: NotFoundComponent } // immer als letztes konfigurieren - erste Route die matched wird angesteuert

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [LoginComponent, DashboardComponent]
