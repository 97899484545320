import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from '../services/message/message.service';
import { ErrorService } from '../services/error/error.service';
import { Observer, Observable } from 'rxjs';
import { Meldung } from '../meldung-item/meldung-item.component';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'btf-meldung-admin-item',
  templateUrl: './meldung-admin-item.component.html',
  styleUrls: ['./meldung-admin-item.component.scss'],
  animations: [
    trigger('fadeInOut', [
        transition(':enter', [   // :enter is alias to 'void => *'
          style({ opacity: 0 }),
          animate(300, style({ opacity: 1 }))
        ]),
        transition(':leave', [   // :leave is alias to '* => void'
          animate(300, style({ opacity: 0 }))
        ])
      ])
  ]
})
export class MeldungAdminItemComponent implements OnInit {
  @Input() meldung : Meldung;
  showInfo : Boolean;

  reader : any[];

  constructor(private messages: MessageService,
    private error: ErrorService) { }

  ngOnInit() {
  }
}
