import { Component, OnInit } from '@angular/core';
import { UserlistService } from '../services/userlist/userlist.service';
import { ErrorService } from '../services/error/error.service';

@Component({
  selector: 'btf-user-create-administration',
  templateUrl: './user-create-administration.component.html',
  styleUrls: ['./user-create-administration.component.scss']
})
export class UserCreateAdministrationComponent implements OnInit {
  userdata : any = {
    BENUTZERNAME: '',
    TITEL: '',
    FIRSTNAME: '',
    LASTNAME: '',
    PASSWORD: '',
    PASSWORD_REPEAT: ''
  };
  isLoaded : boolean = false;

  constructor(
    private userservice: UserlistService,
    private error: ErrorService) {
  }

  ngOnInit() {
    // TODO: change
    this.isLoaded = true;
  }

  save() {
    // TODO: Notify on error on save
    // TODO: Audit changes
    // TODO: Check for invalid usernames (trim(), ...)
    this.userservice.createUser(this.userdata).subscribe(res => {
      this.error.set("Benutzer wurde erfolgreich angelegt!", "alert-success");
    }, err => {
      this.error.set("Der Benutzername ist bereits vergeben!");
    });
  }

}
