import { Component, OnInit } from '@angular/core';
import { DashboardItemComponent } from '../dashboard-item/dashboard-item.component';
import { LoginService } from '../services/login/login.service';

@Component({
  selector: 'btf-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private loginService: LoginService) { }

  ngOnInit() {
  }

  isLoaded() {
    return this.loginService.isLoaded();
  }

  showAdminMenu() {
    var show = false;

    if(this.loginService.canManageUsers()) {
      show = true;
    }

    if(this.loginService.canSendMessages()) {
      show = true;
    }

    return show;
  }
}
