import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DienstbuchService {
  constructor(private http: HttpClient, @Inject("API_BASE_URL") private api: String) { }

  getEntryForDate(type: String, date: Date) {
    return this.http.get<any[]>(this.api + "dienstbuch/" + date.getFullYear() + "/" + (date.getMonth()+1) + "/" + date.getDate() + "/" + type);
  }

    setEntryForDate(type: String, date: Date, data: any[]) {
      return Observable.create((observer: Observer<Object>) => {
        this.http.post(this.api + "dienstbuch/" + date.getFullYear() + "/" + (date.getMonth()+1) + "/" + date.getDate() + "/" + type, data).subscribe(res => {
          observer.next(res);
          observer.complete();
        },
        (error: Response) => {
          observer.error(error);
        });
      });
    }
}
