import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observer, Observable, interval } from 'rxjs';
import { Router } from '@angular/router';

const CURRENT_TOKEN = 'token';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _isLoggedIn = null;
  private data : Object;
  private loaded : boolean = false;

  constructor(private http: HttpClient,
    public router: Router,
    @Inject("API_BASE_URL") private api: String) {
      var ptr = this;
      const source = interval(5000);
      const subscribe = source.subscribe(val => {
        ptr.updateLoggedInState().subscribe(res => {
        })
      });
    }

    login(name : String, password : String) {
      return Observable.create((observer: Observer<Object>) => {
        this.http.post(this.api + "user/login", {"username": name, "password": password}, {responseType: 'text', headers:{'Cache-Control': 'no-cache'}}).subscribe(res => {
          observer.next(res);
          this._isLoggedIn = true;
          localStorage.setItem(CURRENT_TOKEN, res); // TODO: Remove
          observer.complete();
        },
        (error: Response) => {
          observer.error(error);
        });
      });
    }

    logout() {
      localStorage.setItem(CURRENT_TOKEN, "");
      localStorage.removeItem(CURRENT_TOKEN);
    }

    getToken() {
      return localStorage.getItem(CURRENT_TOKEN);
    }

    updateLoggedInState() {
      return Observable.create((observer: Observer<Boolean>) => {
        if(localStorage.getItem(CURRENT_TOKEN) == null)
        observer.next(false);

        this.http.get(this.api + "user/current").subscribe(res => {
          this._isLoggedIn = true;
          this.data = res;
          this.loaded = true;
          observer.next(true);
        },
        (error: HttpResponse<any>) => {
          this._isLoggedIn = false;
          this.data = [];
          observer.next(false);
          this.router.navigate(['/login']);
        });
      });
    }

    getUserData(key : string) {
      if(this.data == undefined) {
        return false;
      }
      if(key in this.data) {
        return this.data[key];
      }
      return false;
    }

    getId() {
      return this.getUserData("id");
    }

    canManageUsers() {
      return this.getUserData("ROLE_MANAGE_USERS");
    }

    canManagePermissions() {
      return this.getUserData("ROLE_MANAGE_PERMISSIONS");
    }

    canSendMessages() {
      return this.getUserData("ROLE_SEND_MESSAGES");
    }

    canShowGeneralData() {
      return this.getUserData("ROLE_SHOW_GENERAL_DATA");
    }

    canSetGeneralData() {
      return this.getUserData("ROLE_SET_GENERAL_DATA");
    }

    canShowPersonalData() {
      return this.getUserData("ROLE_SHOW_PERSONAL_DATA");
    }

    canSetPersonalData() {
      return this.getUserData("ROLE_SET_PERSONAL_DATA");
    }

    canShowFunctions() {
      return this.getUserData("ROLE_SHOW_FUNCTIONS");
    }

    canSetFunctions() {
      return this.getUserData("ROLE_SET_FUNCTIONS");
    }

    canShowSondertaetigkeiten() {
      return this.getUserData("ROLE_SHOW_SONDERTAETIGKEITEN");
    }

    canSetSondertaetigkeitenASW() {
      return this.getUserData("ROLE_SET_SONDERTAETIGKEITEN_ASW");
    }

    canSetSondertaetigkeitenBSW() {
      return this.getUserData("ROLE_SET_SONDERTAETIGKEITEN_BSW");
    }

    canSetSondertaetigkeitenMGW() {
      return this.getUserData("ROLE_SET_SONDERTAETIGKEITEN_MGW");
    }

    canSetSondertaetigkeitenTW() {
      return this.getUserData("ROLE_SET_SONDERTAETIGKEITEN_TW");
    }

    isLoaded() {
      return this.loaded;
    }

    isLoggedIn() {
      if(this._isLoggedIn == true && localStorage.getItem(CURRENT_TOKEN) != null) {
        return true;
      }
      return false;
    }
  }
