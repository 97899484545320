import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from '../services/message/message.service';
import { ErrorService } from '../services/error/error.service';
import { Observer, Observable } from 'rxjs';

export class Meldung {
  constructor(
    public id: number,
    public title: string,
    public message: string,
    public date: Date,
    public read: Date,
    public signatures : any[]) {
  }
}

@Component({
  selector: 'btf-meldung-item',
  templateUrl: './meldung-item.component.html',
  styleUrls: ['./meldung-item.component.scss']
})
export class MeldungItemComponent implements OnInit {
  @Input() meldung : Meldung;
  @ViewChild('password') pw : ElementRef;

  constructor(private messages: MessageService,
    private error: ErrorService) { }

  ngOnInit() {
  }

  sign() {
    if(event != undefined) {
      event.preventDefault();
    }
    this.messages.sign(this.meldung.id, this.pw.nativeElement.value).subscribe(res => {
      this.meldung.read = new Date();
    },
    (error: Response) => {
      this.pw.nativeElement.value = "";
      this.error.set("Fehlerhaftes Passwort!");
    });
  }
}
