import { Component, OnInit } from '@angular/core';
import { UserListItemComponent } from '../user-list-item/user-list-item.component';
import { UserlistService } from '../services/userlist/userlist.service';

@Component({
  selector: 'btf-user-administration-list',
  templateUrl: './user-administration-list.component.html',
  styleUrls: ['./user-administration-list.component.scss']
})

export class UserAdministrationListComponent implements OnInit {
  public users : User[] = [];
  constructor(private userservice: UserlistService) { }

  ngOnInit() {
    var ptr = this;
    this.userservice.getUsers().subscribe(res => {
      res.forEach(function (e) {
        ptr.users = [...ptr.users, new User(e.id, e.name)];
      });
    });
  }
}

class User {
    constructor(
      public uid: number,
      public username: string
    ) {}
}
