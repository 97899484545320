import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
    private errors : any[] = [];

    constructor() {
    }

    set(message : String, type? : String) {
      var ptr = this;
      if(!type) {
        type = "alert-danger";
      }

      this.errors = [...this.errors, [message, type]];
      if(this.errors.length == 1) {
        setTimeout(function() {
          ptr.popLastMessage();
        }, 2000);
      }
    }

    popLastMessage() {
      this.errors.shift();

      if(this.errors.length >= 1) {
        var ptr = this;
        setTimeout(function() {
          ptr.popLastMessage();
        }, 2000);
      }
    }

    isError() {
      return this.errors.length > 0;
    }

    getErrorMessage() {
      if(this.isError())
        return this.errors[0][0];
      return "";
    }

    getErrorType() {
      if(this.isError())
        return this.errors[0][1];
      return "";
    }
}
