import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  constructor(private http: HttpClient, @Inject("API_BASE_URL") private api: String) { }

  getShiftsForDate(month: number, year: number) {
    return this.http.get<any[]>(this.api + "shift/" + year + "/" + month);
  }

  setShiftForDate(day: number, month: number, year: number, uid: number, type : String) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "shift/" + year + "/" + month + "/" + day, [{"uid": uid, "type": type, "function": null}]).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  setShiftFunctionForDate(day: number, month: number, year: number, uid: number, pos : String) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "shift/" + year + "/" + month + "/" + day, [{"uid": [uid], "type": null, "function": pos}]).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  setShiftAttributeForDate(attribute: string, day: number, month: number, year: number, uid: number, data : String) {
    return Observable.create((observer: Observer<Object>) => {
      var dat = [{"uid": [uid], "type": null, "function": null}];
      dat[0][attribute] = data;
      this.http.post(this.api + "shift/" + year + "/" + month + "/" + day, dat).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }
}
