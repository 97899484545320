import { Component, OnInit } from '@angular/core';
import { MessageService } from '../services/message/message.service';
import { MeldungItemComponent, Meldung } from '../meldung-item/meldung-item.component';
@Component({
  selector: 'btf-meldungen',
  templateUrl: './meldungen.component.html',
  styleUrls: ['./meldungen.component.scss']
})
export class MeldungenComponent implements OnInit {

  meldungen = [];

  constructor(private messages: MessageService) {
  }

  ngOnInit() {
    var ptr = this;
    this.messages.getMessages().subscribe(res => {
      res.forEach(function (e) {
        ptr.meldungen = [...ptr.meldungen, new Meldung(e.id, e.title, e.message, e.created, e.read, null)];
      });
    });
  }

}
