import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserlistService } from '../services/userlist/userlist.service';
import { ShiftService } from '../services/shift/shift.service';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'btf-tagesplan',
  templateUrl: './tagesplan.component.html',
  styleUrls: ['./tagesplan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagesplanComponent implements OnInit {
  allUsers: any[] = [];
  usersInShift : any[] = [];
  usersNotInShift : any[] = [];
  selectedDate : Date = new Date();
  selectedType : String = "";

  prevDate : Date = null;
  nextDate : Date = null;
  prevType : String;
  nextType : String;

  positionen : any[] = [];

  disabled : boolean = false;

  // Also change in PHP!
  musterPositionen : any[] = [
    {key: "KDO", text: "KDO – Bereitschaft", req: 'FUNKTION_KDO_BEREITSCHAFT', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "ZKDT", text: "ZKDT", req: 'FUNKTION_ZKDT', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "GKDT", text: "GKDT", req: 'FUNKTION_GKDT', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "TEL1", text: "Tel 1", req: 'FUNKTION_ZENTRALE', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "TEL2", text: "Tel 2", req: 'FUNKTION_ZENTRALE', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "TLFMA", text: "TLF MA", req: 'FUNKTION_TLF_MA', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "TLF1", text: "TLF", req: 'FUNKTION_TLF', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "TLF2", text: "TLF", req: 'FUNKTION_TLF', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "RUESTMA", text: "RÜST MA", req: 'FUNKTION_RUEST_MA', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "RUEST", text: "RÜST", req: 'FUNKTION_RUEST', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "LUFMA", text: "LUF MA", req: 'FUNKTION_LUF_MA', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "ULF", text: "ULF", req: null, currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "ULFMA", text: "ULF MA", req: 'FUNKTION_ULF_MA', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "RTW1", text: "RTW 1", req: 'FUNKTION_SANITAETER', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "RTW2", text: "RTW 2", req: 'FUNKTION_SANITAETER', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''},
    {key: "ELFMA", text: "ELF MA", req: 'FUNKTION_ELF_MA', currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: '', suggestion: '', old: ''}
  ]
    // Also change in PHP!

  // TODO: https://stackoverflow.com/questions/1895476/how-to-style-a-select-dropdown-with-only-css
  // TODO: Locally host external scripts etc.



  constructor(private userservice: UserlistService,
    private shiftservice: ShiftService,
    private cd: ChangeDetectorRef) {
    }

    setSelectedDate(date: Date, type : String) {
      this.selectedDate = date;
      this.selectedType = type;

      this.disabled = (date < new Date(new Date().setHours(0,0,0)));

      if(type == 'T') {
        this.prevType = 'N';
        this.nextType = 'N';

        this.nextDate = new Date(date);
        this.prevDate = new Date(date);
        this.prevDate.setDate(date.getDate() - 1);
      } else {
        this.prevType = 'T';
        this.nextType = 'T';

        this.nextDate = new Date(date);
        this.nextDate.setDate(date.getDate() + 1);
        this.prevDate = new Date(date);
      }

      this.loadData();
    }

    getUsername(id : any) {
      if(typeof this.allUsers[id] === 'undefined') {
        return "";
      }
      return this.allUsers[id].name;
    }

    loadData() {
      var ptr = this;
      var curDate = this.selectedDate;
      var month = this.selectedDate.getMonth();
      var year = this.selectedDate.getFullYear();

      this.positionen = JSON.parse(JSON.stringify(this.musterPositionen)); // create a copy - without reference
      this.usersInShift = [];
      this.usersNotInShift = [];


      this.shiftservice.getShiftsForDate(month+1, year).subscribe(res => {
        if(curDate == ptr.selectedDate) {
          res.forEach(function (e) {
            var date = new Date(e.date);
            if(TagesplanComponent.compareDays(date, ptr.selectedDate)) {
              if(e.type == ptr.selectedType) {
                ptr.usersInShift = [...ptr.usersInShift, ptr.allUsers[e.uid]];
                ptr.setShiftFunction(e.uid, e.function, e.taetigkeit, e.werkstatt, e.sonstiges);
              } else {
                ptr.usersNotInShift = [...ptr.usersNotInShift, ptr.allUsers[e.uid]];
                ptr.setShiftFunction(e.uid, e.function, e.taetigkeit, e.werkstatt, e.sonstiges);
              }
            }
          });
          ptr.checkReserveEntries();
          ptr.makeSuggestions();
          ptr.cd.detectChanges();
        }
      });
    }

    makeSuggestions() {
      // TODO: Just demo content
      var ptr = this;
      this.positionen.forEach(function (pos) {
        ptr.usersInShift.forEach(function (user) {
          if(ptr.userservice.hasRequirement(user, pos.req)) {
            if(Math.random() > 0.3) {
              if(pos.suggestion == "" || pos.suggestion == undefined) {
                pos.suggestion = [];
              }
              if(pos.suggestion.length < 3) {
                pos.suggestion = [...pos.suggestion, user];
              }
            }
          }
        });
      })
    }

    ngOnInit() {
      var ptr = this;
      this.userservice.getUsers().subscribe(res => {
        res.forEach(function (e) {
            ptr.allUsers[e.id] = e;
        });

        ptr.setSelectedDate(new Date(), "T");
      });
    }

    static compareDays(d1 : Date, d2 : Date) {
      if(d1.getDate() != d2.getDate() ||
      d1.getMonth() != d2.getMonth() ||
      d1.getFullYear() != d2.getFullYear()) {
        return false;
      }
      return true;
    }

    setShiftFunction(uid: number, func: string, taetigkeit: string, werkstatt: string, sonstiges: string) {
      if(func == null)
        return;
      if(this.positionen.find(x => x.key == func) == null && func.match("^RES")) {
        this.positionen = [...this.positionen, {key: func, text: "Reserve", req: null, currentUser: uid, taetigkeit: taetigkeit, werkstatt: werkstatt, sonstiges: sonstiges, suggestion: '', old: ''}];
      } else {
        this.positionen.find(x => x.key == func).currentUser = uid;
        this.positionen.find(x => x.key == func).taetigkeit = taetigkeit;
        this.positionen.find(x => x.key == func).werkstatt = werkstatt;
        this.positionen.find(x => x.key == func).sonstiges = sonstiges;
        this.positionen.find(x => x.key == func).old = uid;
        this.positionen.find(x => x.key == func).suggestion = '';
      }
    }

    checkReserveEntries() {
      var resFree = false;
      var newId = 1;
      this.positionen.forEach(function (e) {
        if(e.key.match("^RES")) {
          if(e.currentUser == "") {
            resFree = true;
          }
        }
        newId++;
      });
      if(resFree == false) {
        var newResId = 1;
        while(this.positionen.find(x => x.key == "RES" + newResId) != null) {
          newResId++;
        }
        this.positionen = [...this.positionen, {key: "RES" + newResId, text: "Reserve", req: null, currentUser: '', taetigkeit: '', werkstatt: '', sonstiges: ''}];
      }

      this.cd.detectChanges();
    }

    positionChanged(uid: number, func: string, oldUser : number) {
      var w = '';
      var t = '';
      var s = '';
      var ptr = this;

      this.positionen.filter(x => x.currentUser == uid).forEach(function (e) {
        if(e.taetigkeit != '') {
          t = e.taetigkeit;
        }
        if(e.werkstatt != '') {
          w = e.werkstatt;
        }
        if(e.sonstiges != '') {
          s = e.sonstiges;
        }

        e.currentUser = '';
        e.taetigkeit = '';
        e.werkstatt = '';
        e.sonstiges = '';
      });
      this.positionen.find(x => x.key == func).currentUser = uid;
      this.positionen.find(x => x.key == func).werkstatt = w;
      this.positionen.find(x => x.key == func).taetigkeit = t;
      this.positionen.find(x => x.key == func).sonstiges = s;

      if(uid.toString() != '') {
        this.shiftservice.setShiftFunctionForDate(this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, func).subscribe(res => {
        });
        this.shiftservice.setShiftAttributeForDate("taetigkeit", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, t).subscribe(res => {
        });
        this.shiftservice.setShiftAttributeForDate("werkstatt", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, w).subscribe(res => {
        });
        this.shiftservice.setShiftAttributeForDate("sonstiges", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, s).subscribe(res => {
        });
      }

      if(uid.toString() == '' && oldUser.toString() != '') {
        this.shiftservice.setShiftFunctionForDate(this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), oldUser, '-').subscribe(res => {
        });
      }

      this.checkReserveEntries();
      this.cd.detectChanges();
    }

    taetigkeitChanged(event: any, uid: number) {
      this.shiftservice.setShiftAttributeForDate("taetigkeit", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, event.target.value).subscribe(res => {
      });
    }

    werkstattChanged(event: any, uid: number) {
      this.shiftservice.setShiftAttributeForDate("werkstatt", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, event.target.value).subscribe(res => {
      });
    }

    sonstigesChanged(event: any, uid: number) {
      this.shiftservice.setShiftAttributeForDate("sonstiges", this.selectedDate.getDate(), this.selectedDate.getMonth()+1, this.selectedDate.getFullYear(), uid, event.target.value).subscribe(res => {
      });
    }

  }


@Directive({
  selector: '[inputChangeDebounce]'
})
export class InputChangeDebounceDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Output() debounceChange = new EventEmitter();
  private subj = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.subj.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(
      e => this.debounceChange.emit(e)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('change', ['$event'])
  changeEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.subj.next(event);
  }
}
