import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { ErrorService } from '../services/error/error.service';
import { Router } from '@angular/router';

@Component({
  selector: 'btf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('password') pw : ElementRef;

  constructor(private loginService: LoginService,
    private router: Router,
    private error: ErrorService) { }

    ngOnInit() {
      this.loginService.updateLoggedInState().subscribe(res => {
        if(this.loginService.isLoggedIn()) {
          this.router.navigate(['/']);
        }
      })
    }

    getMaxWidth() {
      return 400;
    }

    login(userName: string, password: string) {
      if(typeof event !== 'undefined') {
        event.preventDefault();
      }
      if(userName == "" || password == "")
        return;

      this.loginService.logout();
      const result = this.loginService.login(userName, password).subscribe(res => {
        this.router.navigate(['/']);
      },
      (error: Response) => {
        this.error.set("Ungültige Login-Daten!");
        this.pw.nativeElement.value = '';
      });
    }

  }
