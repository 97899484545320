import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { ContextMenuModule } from 'ngx-contextmenu';

import { NgxEditorModule } from 'ngx-editor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';

import { LoginService } from './services/login/login.service';
import { DienstbuchService } from './services/dienstbuch/dienstbuch.service';
import { ErrorService } from './services/error/error.service';
import { MessageService } from './services/message/message.service';
import { ShiftService } from './services/shift/shift.service';

import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { DienstbuchComponent } from './dienstbuch/dienstbuch.component';
import { MeldungenComponent } from './meldungen/meldungen.component';
import { MeldungItemComponent } from './meldung-item/meldung-item.component';

import { DpDatePickerModule } from 'ng2-date-picker';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/login/token.interceptor.service';
import { UserAdministrationListComponent } from './user-administration-list/user-administration-list.component';
import { UserListItemComponent } from './user-list-item/user-list-item.component';
import { DateSortPipe, UserEditAdministrationComponent } from './user-edit-administration/user-edit-administration.component';
import { MeldungenAdministrationComponent } from './meldungen-administration/meldungen-administration.component';
import { MeldungAdminItemComponent } from './meldung-admin-item/meldung-admin-item.component';
import { SchichtplanComponent, SearchFilterPipe } from './schichtplan/schichtplan.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TagesplanComponent, InputChangeDebounceDirective } from './tagesplan/tagesplan.component';
import { UserCreateAdministrationComponent } from './user-create-administration/user-create-administration.component';
import { SettingsComponent } from './settings/settings.component';

const API_BASE_URL_CONSTANT = "https://verwaltung.btf-chemiepark.com/api/public/"

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ErrorComponent,
    DashboardItemComponent,
    DienstbuchComponent,
    MeldungenComponent,
    MeldungItemComponent,
    UserAdministrationListComponent,
    UserListItemComponent,
    UserEditAdministrationComponent,
    MeldungenAdministrationComponent,
    MeldungAdminItemComponent,
    SchichtplanComponent,
    SearchFilterPipe,
    DateSortPipe,
    NotFoundComponent,
    TagesplanComponent,
    InputChangeDebounceDirective,
    UserCreateAdministrationComponent,
    SettingsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DpDatePickerModule,
    NgxEditorModule,
    ContextMenuModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de-AT" },
    LoginService,
    ErrorService,
    MessageService,
    ShiftService,
    DienstbuchService,
    { provide: "API_BASE_URL", useValue: API_BASE_URL_CONSTANT },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
