import { HostListener, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserlistService } from '../services/userlist/userlist.service';
import { ShiftService } from '../services/shift/shift.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import * as $ from 'jquery';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'searchfilter'
})
@Injectable()
export class SearchFilterPipe implements PipeTransform {
 transform(items: any[], value: string): any[] {
   if (!items) return [];
   return items.filter(it => it.schichtgruppe == value);
 }
}

@Component({
  selector: 'btf-schichtplan',
  templateUrl: './schichtplan.component.html',
  styleUrls: ['./schichtplan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchichtplanComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public contextMnu: ContextMenuComponent;

  public static MINIMALSTAND = 14;

  warnmeldung : String;
  columns : Column[] = [];
  prevDate : Date;
  selectedDate : Date = new Date();
  nextDate : Date;
  users : User[] = [];

  shiftData : any[] = [];
  warnings : any[] = [];

  isLoaded : boolean = false;

  constructor(private userservice: UserlistService,
              private shiftservice: ShiftService,
              private cd: ChangeDetectorRef,
              private contextMenuService: ContextMenuService) {
    this.setSelectedDate(new Date());
  }

  setSelectedDate(date: Date) {
    this.selectedDate = date;

    this.prevDate = new Date(this.selectedDate);
    this.prevDate.setDate(1);
    this.prevDate.setMonth(this.selectedDate.getMonth()-1);

    this.nextDate = new Date(this.selectedDate);
    this.nextDate.setDate(1);
    this.nextDate.setMonth(this.selectedDate.getMonth()+1);
  }

  setDate(date: Date) {
    this.isLoaded = false;
    this.cd.detectChanges();
    this.setSelectedDate(date);
    this.ngOnInit();
  }

  setWarning(meldung: string) {
    if(this.warnmeldung == null) {
      this.warnmeldung = meldung;
    }
  }

  getClassForShift(type: string) {
    if(type == undefined) {
      return "";
    }
    return type;
  }

  getWarningClass(uid: string, day: string) {
    if(this.warnings[uid][day] != undefined) {
      return "warning";
    }
    return "";
  }

  getWarningClassTotal(group: string, day: string) {
    var total = this.getTotalForGroup(group, day);
    if(total < SchichtplanComponent.MINIMALSTAND && total > 0) {
      return "warning";
    }
    return "";
  }

  ngOnInit() {
    var ptr = this;

    this.shiftData = [];
    this.users = [];
    this.columns = [];

    var month = this.selectedDate.getMonth();
    var year = this.selectedDate.getFullYear();

    var firstDay = new Date(year, month, 1);
    var lastDay = new Date(year, month + 1, 0);

    this.columns.push(new Column('mitarbeiter', 'Mitarbeiter'));

    var weekday = new Array();
    weekday[0] = "So";
    weekday[1] = "Mo";
    weekday[2] = "Di";
    weekday[3] = "Mi";
    weekday[4] = "Do";
    weekday[5] = "Fr";
    weekday[6] = "Sa";

    var j = firstDay.getDay();
    for(var i=1; i<=lastDay.getDate(); i++) {
      this.columns.push(new Column('day ' + weekday[j], i + "", weekday[j]));
      j=(j+1)%7;
    }

    this.userservice.getUsers().subscribe(res => {
      if(month != this.selectedDate.getMonth())
        return;
      res.forEach(function (e) {
        ptr.users = [...ptr.users, new User(e.id, e.name, e.schichtgruppe)];
        ptr.shiftData[e.id] = [];
        ptr.warnings[e.id] = [];
      });

      this.shiftservice.getShiftsForDate(month+1, year).subscribe(res => {
        res.forEach(function (e) {
          var date = new Date(e.date);
          var type = ""
          switch(e.type) {
            case undefined:
              type = "F";
            case 'T':
            case 'F':
            case 'N':
            case 'K':
            case 'F':
            case 'U':
            case 'ZA':
            case 'DF':
            case 'DR':
            case 'KU':
            case 'KA':
            case 'S':
            case 'SV':
            case 'PF':
            case 'BR':
              type = "type" + e.type;
              break;
            default:
              type = "?";
              break;
          }
          ptr.shiftData[e.uid][date.getDate()] = type;
        });
        ptr.checkForErrors();
      });
    });
  }

  public checkForErrors() {
    this.warnmeldung = null;
    var maxDays = 0;
    // TODO: Check first and last day of month
    for(var uid in this.shiftData) {
      this.warnings[uid] = [];
      if(maxDays < this.shiftData[uid].length) {
        maxDays = this.shiftData[uid].length;
      }

      for(var i=0; i<this.shiftData[uid].length; i++) {
        var e = this.shiftData[uid];
        if(e[i] != null && e[i+1] != null && e[i].startsWith('typeN') && e[i+1].startsWith('typeT')) { // N->T
          this.warnings[uid][i] = true;
          this.warnings[uid][i+1] = true;
          this.setWarning("Ruhezeiten nicht immer eingehalten!");
        }
      }
    }

    for(var i=0; i<maxDays; i++) {
      var totalA = this.getTotalForGroup('A', '' + i);
      var totalB = this.getTotalForGroup('B', '' + i);
      var totalC = this.getTotalForGroup('C', '' + i);
      if((totalA > 0 && totalA < SchichtplanComponent.MINIMALSTAND) ||
         (totalB > 0 && totalB < SchichtplanComponent.MINIMALSTAND) ||
         (totalC > 0 && totalC < SchichtplanComponent.MINIMALSTAND)) {
          this.setWarning("Personalstand unter Mindeststand!");
          break;
         }
    }
    this.isLoaded = true;
    this.cd.detectChanges();
  }

  public changeShift(event: any, type: String) {
    var uid = event.item[0];
    var day = event.item[1];
    var month = this.selectedDate.getMonth()+1;
    var year = this.selectedDate.getFullYear();

    if(!Array.isArray(uid)) {
      uid = [uid];
    }
    var ptr = this;
    this.shiftservice.setShiftForDate(day, month, year, uid, type).subscribe(res => {
        for(var index in uid) {
          ptr.shiftData[uid[index]][day] = 'type' + type;
        }
        ptr.checkForErrors();
    });
  }

  @HostListener('click', ['$event'])
  public onContextMenu($event: MouseEvent, item: any): void {
    if(item != undefined) {
      this.contextMenuService.show.next({
        contextMenu: this.contextMnu,
        event: $event,
        item: item,
      });
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  public onOpenContextMenu(elem: any) {
    $(elem.event.target).addClass('selected');
  }

  public onCloseContextMenu() {
    $('.selected').removeClass('selected');
  }

  public getTotalForGroup(group: string, day: string) {
    var sum = 0;
    for(var uid in this.shiftData) {
      if(this.users.find(function (e) { return e.uid == parseInt(uid);}).schichtgruppe == group) {
        var type = this.shiftData[uid][day];
        if(type == 'typeT' || type == 'typeN') {
          sum++;
        }
      }
    }
    return sum == 0 ? '' : sum;
  }

  public getHolidayForGroup(group: string, day: string) {
    var sum = 0;
    for(var uid in this.shiftData) {
      if(this.users.find(function (e) { return e.uid == parseInt(uid);}).schichtgruppe == group) {
        var type = this.shiftData[uid][day];
        if(type == 'typeU') {
          sum++;
        }
      }
    }
    return sum == 0 ? '' : sum;
  }

  public getWochenstunden(uid : number, day : number) {
    var sum = 0;
    // TODO: check previous month!
    for(var i=day-6; i<=day; i++) {
      // TODO: which types are working shifts?
      sum += (this.shiftData[uid][i] == 'typeT' || this.shiftData[uid][i] == 'typeN') ? 12 : 0;
    }
    return sum;
  }

  /******/
  public generateMuster() {
    var muster = ['T', 'T', 'N', 'N', 'F', 'F'];


    var month = this.selectedDate.getMonth();
    var year = this.selectedDate.getFullYear();

    var firstDay = new Date(year, month, 1);
    var lastDay = new Date(year, month + 1, 0);

    var j = firstDay.getDay();
    for(var i=1; i<=lastDay.getDate(); i++) {

      var shiftA = [];
      var shiftB = [];
      var shiftC = [];

      for(var index in this.users) {
        var user = this.users[index];
        if(user.schichtgruppe == 'A') {
          shiftA = [...shiftA, user.uid];
        } else if(user.schichtgruppe == 'B') {
          shiftB = [...shiftB, user.uid];
        } else if(user.schichtgruppe == 'C') {
          shiftC = [...shiftC, user.uid];
        }
      }

      var type = i-1;
      this.changeShift({'item': [shiftA, i]}, muster[type%6]);
      this.changeShift({'item': [shiftB, i]}, muster[(type+2)%6]);
      this.changeShift({'item': [shiftC, i]}, muster[(type+4)%6]);
    }
  }
  /******/
}

class Column {
    constructor(
      public attrClass: string,
      public title: string,
      public subtitle: string = null) {
    }
}

class User {
    constructor(
      public uid: number,
      public username: string,
      public schichtgruppe: string
    ) {}
}
