import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { LoginService } from '../services/login/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private loginService: LoginService,
              private router: Router) {

              }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.loginService.isLoggedIn()) {
        return true;
      }

      return Observable.create((observer: Observer<boolean>) => {
        this.loginService.updateLoggedInState().subscribe(res => {
          if(!this.loginService.isLoggedIn()) {
            this.router.navigate(['/login']);
            return observer.next(false);
          } else {
            return observer.next(true);
          }
        });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class canManageUsersGuard implements CanActivate {
  constructor(private loginService: LoginService,
              private router: Router) {

              }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.loginService.isLoggedIn()) {
        if(!this.loginService.canManageUsers()) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }

      return Observable.create((observer: Observer<boolean>) => {
        this.loginService.updateLoggedInState().subscribe(res => {
          if(!this.loginService.canManageUsers()) {
            this.router.navigate(['/']);
            return observer.next(false);
          } else {
            return observer.next(true);
          }
        });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class canSendMessagesGuard implements CanActivate {
  constructor(private loginService: LoginService,
              private router: Router) {

              }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.loginService.isLoggedIn()) {
        if(!this.loginService.canSendMessages()) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }

      return Observable.create((observer: Observer<boolean>) => {
        this.loginService.updateLoggedInState().subscribe(res => {
          if(!this.loginService.canSendMessages()) {
            this.router.navigate(['/']);
            return observer.next(false);
          } else {
            return observer.next(true);
          }
        });
    });
  }
}
