import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserlistService } from '../services/userlist/userlist.service';
import { ShiftService } from '../services/shift/shift.service';
import { DienstbuchService } from '../services/dienstbuch/dienstbuch.service';
import { ErrorService } from '../services/error/error.service';

@Component({
  selector: 'btf-dienstbuch',
  templateUrl: './dienstbuch.component.html',
  styleUrls: ['./dienstbuch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DienstbuchComponent implements OnInit {
  // TODO: implement isLladed
  isLoaded : Boolean = true;

  selectedDate : Date = new Date();
  selectedType : String = "";

  prevDate : Date = null;
  nextDate : Date = null;
  prevType : String;
  nextType : String;

  disabled : Boolean;

  // TODO: Remove
  todo : any[] = [];

  data = [];

  settingsDate = {
    locale: 'de-at',
    firstDayOfWeek: 'mo',
    format: 'DD.MM.YYYY',
    min: "01.01.2000"
  }
  settingsTime = {
    locale: 'de-at',
    format: 'HH:mm',
    showTwentyFourHours: true
  }


  constructor(private userservice: UserlistService,
    private shiftservice: ShiftService,
    private dienstbuchservice: DienstbuchService,
    private error: ErrorService,
    private cd: ChangeDetectorRef) {

    this.selectedDate.setHours(0,0,0,0);
    this.setSelectedDate(this.selectedDate, 'T');

/*
    this.data = <any>{
      DIENSTGRUPPE: 'Y',
      DATE: this.selectedDate,
      SCHICHT: 'Tag',
      ZENTRALIST_1: 'Person 1',
      ZENTRALIST_2: 'Person 2',
      KDO_BEREITSCHAFT: 'Person 3',
      ZKDT: 'Person 4',
    }*/
   }

   setSelectedDate(date: Date, type : String) {
     this.selectedDate = date;
     this.selectedType = type;

     this.disabled = (date < new Date());

     if(type == 'T') {
       this.prevType = 'N';
       this.nextType = 'N';

       this.nextDate = new Date(date);
       this.prevDate = new Date(date);
       this.prevDate.setDate(date.getDate() - 1);
     } else {
       this.prevType = 'T';
       this.nextType = 'T';

       this.nextDate = new Date(date);
       this.nextDate.setDate(date.getDate() + 1);
       this.prevDate = new Date(date);
     }

     this.loadData();
   }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    var ptr = this;
    // TODO: Schicht
    this.dienstbuchservice.getEntryForDate(this.selectedType, this.selectedDate).subscribe(res => {
      ptr.data = res;
      ptr.checkDynamicfields();
      // TODO: Remove for production
      console.log(res);
    });
  }

  save() {
    // TODO: Notify on error on save
    // TODO: Audit changes
    this.dienstbuchservice.setEntryForDate(this.selectedType, this.selectedDate, this.data).subscribe(res => {
      this.error.set("Änderungen wurden gespeichert!", "alert-success");
    }, err => {
      this.error.set("Änderungen konnten nicht gespeichert werden!");
    });
  }

  getTimeDiff(time1: any, time2: any) {
    if(typeof time1 != 'string' || typeof time2 != 'string')
      return "-";

    var von = time1.split(':');
    var bis = time2.split(':');

    var vonMin = parseInt(von[0])*60+parseInt(von[1]);
    var bisMin = parseInt(bis[0])*60+parseInt(bis[1]);

    if(bisMin < vonMin) {
      bisMin += 24*60;
    }

    var diff = bisMin-vonMin;

    var diffHour = Math.floor(diff/60);
    var diffMin = (diff%60);

    return (diffHour <= 9 ? '0' : '') + diffHour + ":" + (diffMin <= 9 ? '0' : '') + diffMin;
  }

  checkDynamicfields() {
    var items = ["FUNKTIONSKONTROLLEN", "ELA_STOERUNGEN", "BMA_ALARME", "BMA_STOERUNGEN", "EXTERNER_RTW", "EINSAETZE", "ALLEINARBEITSPLATZUEBERWACHUNG", "AGAP", "STURMWARNUNGEN", "POSTEN_FEUERWEHR", "POSTEN_BETRIEB", "POSTEN_WINKLER"];
    var values = [];

    values["FUNKTIONSKONTROLLEN"] = {BESCHREIBUNG: '', KDO_INFORMIERT: false, FACHFIRMA_INFORMIERT: false, DATUM: '', ANSPRECHPARTNER: ''};
    values["ELA_STOERUNGEN"] = {BAU: '', KDO_INFORMIERT: false, KAPPACHER_INFORMIERT: false, DATUM: '', ANSPRECHPARTNER: ''};
    values["BMA_ALARME"] = {NUMMER: '', BAU: '', BESCHREIBUNG: '', KDO_INFORMIERT: false, BETRIEB_INFORMIERT: false, DATUM: '', ANSPRECHPARTNER: ''};
    values["BMA_STOERUNGEN"] = {NUMMER: '', BAU: '', BESCHREIBUNG: '', KDO_INFORMIERT: false, BETRIEB_INFORMIERT: false, DATUM: '', ANSPRECHPARTNER: ''};
    values["EXTERNER_RTW"] = {ZEIT: '', MIT_EINSATZ: false, TRANSPORT: false};
    values["EINSAETZE"] = {ALARM: '', BAU: '', EINSATZART: '', VON: '', BIS: '', BEMERKUNG: ''};
    values["ALLEINARBEITSPLATZUEBERWACHUNG"] = {AUSLOESER: '', NAME: '', VON: '', BIS: '', BEMERKUNG: ''};
    values["AGAP"] = {ZEIT: '', BAU: '', MELDUNGSART: '', BEMERKUNG: ''};
    values["STURMWARNUNGEN"] = {ZEIT: '', KMH: '', WINDRICHTUNG: '', BEMERKUNG: ''};
    values["POSTEN_FEUERWEHR"] = {BAU: '', NAME: '', VON: '', BIS: '', POSTENART: ''};
    values["POSTEN_BETRIEB"] = {FIRMA: '', BAU: '', NAME: '', VON: '', BIS: '', POSTENART: ''};
    values["POSTEN_WINKLER"] = {BAU: '', NAME: '', VON: '', BIS: '', POSTENART: ''};

    var ptr = this;
    items.forEach(function (name) {
      var empty = 0;
      if(typeof ptr.data[name] !== "object" || ptr.data[name] == null) {
        ptr.data[name] = Array();
      }
      ptr.data[name].forEach(function (e) {
        var notEmpty = 0;
        for(var propertyName in e) {
           if(e[propertyName] != "" && e[propertyName] != undefined) {
             notEmpty = 1;
             break;
           }
        }
        if(!notEmpty) {
          empty++;
        }
      });
      if(empty == 0) {
        ptr.data[name].push(values[name]);
      }
    });
    this.cd.detectChanges();
  }

}
