import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserlistService {

  constructor(private http: HttpClient, @Inject("API_BASE_URL") private api: String) { }

  getUsers() {
    return this.http.get<any[]>(this.api + "admin/user/list");
  }

  getUserInfo(id : Number) {
    return this.http.get<any[]>(this.api + "admin/user/" + id);
  }

  createUser(data: any[]) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "admin/newuser", data).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  setUserInfo(id : Number, data: any[]) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "admin/user/" + id, data).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  setUserPassword(id : Number, data: any[]) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "admin/userpass/" + id, data).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  setOwnPassword(data: any[]) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "user/password", data).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  resetZA_PF_U(id : Number, data: any[]) {
    return Observable.create((observer: Observer<Object>) => {
      this.http.post(this.api + "admin/zapfu/" + id, data).subscribe(res => {
        observer.next(res);
        observer.complete();
      },
      (error: Response) => {
        observer.error(error);
      });
    });
  }

  hasRequirement(user: any, req: any) {
    if(req == null) {
      return true;
    }
    if(req == "FUNKTION_SANITAETER") {
      return user.sanitaeter != "";
    }
    return user.funktionen[req] != null && user.funktionen[req];
  }
}
