import { Component, OnInit, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ErrorService } from '../services/error/error.service';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'btf-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  animations: [
    trigger('fadeInOut', [
        transition(':enter', [   // :enter is alias to 'void => *'
          style({ opacity: 0 }),
          animate(300, style({ opacity: 1 }))
        ]),
        transition(':leave', [   // :leave is alias to '* => void'
          animate(300, style({ opacity: 0 }))
        ])
      ])
  ]
})
export class ErrorComponent implements OnInit {
  constructor(public error: ErrorService) {
  }
  ngOnInit() {
  }
}
