import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserlistService } from '../services/userlist/userlist.service';
import { ErrorService } from '../services/error/error.service';
import { LoginService } from '../services/login/login.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateSortPipe } from '../user-edit-administration/user-edit-administration.component';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  private id: Number;
  userdata : any[];
  pwdata : any;
  isLoaded : boolean = false;
  za_pf_u_customized : any = {ANPASSUNG_TYP : '', ANPASSUNG_RESTSTUNDEN: ''};

  ZA : any[];
  PF : any[];
  U : any[];

  SUMME_ZA : number = 0.0;
  SUMME_PF: number = 0.0;
  SUMME_U : number = 0.0;

  settings = {
    locale: 'de-at',
    firstDayOfWeek: 'mo',
    format: 'DD.MM.YYYY',
    min: '01.01.1900'
  }

  public Dienstgrade = [
    'PFM',

    'FM',
    'OFM',
    'HFM',

    'LM',
    'OLM',
    'HLM',

    'BM',
    'OBM',
    'HBM',

    'AW',
    'OAW',
    'HAW',

    'BI',
    'OBI',
    'HBI',
    'ABI',

    'BR',
    'OBR'
  ];

  constructor(private route: ActivatedRoute,
    private userservice: UserlistService,
    private error: ErrorService,
    private loginService: LoginService,
    private cd: ChangeDetectorRef) {

    }

    ngOnInit() {
      var ptr = this;
      ptr.ZA = [];
      ptr.PF = [];
      ptr.U = [];
      ptr.SUMME_U = 0.0;
      ptr.SUMME_ZA = 0.0;
      ptr.SUMME_PF = 0.0;
      ptr.userdata = [];
      ptr.pwdata = {};
      ptr.isLoaded = false;

      ptr.id = ptr.loginService.getId();
      ptr.userservice.getUserInfo(ptr.id).subscribe(res => {
        ptr.userdata = res;
        ptr.isLoaded = true;

        if(!("SHIFTS" in res)) {
          return;
        }

        var shift = [];

        res["SHIFTS"]["SHIFT"].forEach(function (e) {
          if(e.type == 'ZA' || e.type == 'PF' || e.type == 'U') {
            var tmp = e.date.split("-");
            e.date = tmp[2] + "." + tmp[1] + "." + tmp[0];
            e.duration = "-12.00";

            var value = parseFloat(e.duration);
            if(e.type == 'ZA') {
              e.type = 'Zeitausgleich';
              ptr.ZA = [...ptr.ZA, e];
              ptr.SUMME_ZA += value;
            } else if(e.type == 'PF') {
              e.type = 'Pflegefreistellung';
              ptr.PF = [...ptr.PF, e];
              ptr.SUMME_PF += value;
            } else if(e.type == 'U') {
              e.type = 'Urlaub';
              ptr.U = [...ptr.U, e];
              ptr.SUMME_U += value;
            }
          }
        });

        res["SHIFTS"]["ANPASSUNGEN"].forEach(function (e) {
          if(e.type == 'ZA' || e.type == 'PF' || e.type == 'U') {
            var tmp = e.date.split("-");
            e.date = tmp[2] + "." + tmp[1] + "." + tmp[0];

            var value = parseFloat(e.duration);
            if(e.type == 'ZA') {
              e.type = 'Zeitausgleich Anpassung durch ' + e.editor;
              ptr.ZA = [...ptr.ZA, e];
              ptr.SUMME_ZA += value;
            } else if(e.type == 'PF') {
              e.type = 'Pflegefreistellung Anpassung durch ' + e.editor;
              ptr.PF = [...ptr.PF, e];
              ptr.SUMME_PF += value;
            } else if(e.type == 'U') {
              e.type = 'Urlaub Anpassung durch ' + e.editor;
              ptr.U = [...ptr.U, e];
              ptr.SUMME_U += value;
            }
          }
        });
        ptr.cd.detectChanges();
        window.scroll(0,0);
      });
    }

    save() {
      // TODO: Notify on error on save
      // TODO: Audit changes

      //todo
      this.userservice.setUserInfo(this.id, this.userdata).subscribe(res => {
        this.error.set("Änderungen wurden gespeichert!", "alert-success");
        this.ngOnInit();
      }, err => {
        this.error.set("Änderungen konnten nicht gespeichert werden!");
      });
    }

    save3() {
      // TODO: Notify on error on save
      // TODO: Audit changes
      //todo
      this.userservice.setOwnPassword(this.pwdata).subscribe(res => {
        this.error.set("Passwort wurde geändert!", "alert-success");
        this.ngOnInit();
      }, err => {
        this.error.set("Das Passwort konnte nicht geändert werden!");
      });
    }

    getAge(eintritt : String) {
      if(eintritt == null || eintritt == "" || +Date.now() < +eintritt.toDate('dd.mm.yyyy'))
      return "-";
      var ageDifMs = +Date.now() - +eintritt.toDate('dd.mm.yyyy');
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getTageString(stunden : number) {
      return Math.abs(stunden) == 12 ? 'Tag' : 'Tage';
    }
  }
