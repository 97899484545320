import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'btf-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent implements OnInit {
  @Input() title: String;
  @Input() route: String;
  @Input() image: String;

  constructor() { }

  ngOnInit() {
  }

  isDisabled() {
    return this.route == "#";
  }

}
