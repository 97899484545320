import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../services/message/message.service';
import { ErrorService } from '../services/error/error.service';
import { Meldung } from '../meldung-item/meldung-item.component';

@Component({
  selector: 'btf-meldungen-administration',
  templateUrl: './meldungen-administration.component.html',
  styleUrls: ['./meldungen-administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeldungenAdministrationComponent implements OnInit {
  meldungen = [];
  loaded : boolean = false;

  caption : String;
  htmlContent : String;

  editorConfig = {
    "editable": true,
    "spellcheck": true,
    "height": "auto",
    "minHeight": "15rem",
    "width": "auto",
    "minWidth": "0",
    "translate": "yes",
    "enableToolbar": true,
    "showToolbar": true,
    "placeholder": "Nachricht",
    "imageEndPoint": "",
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough"],
      ["indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "horizontalLine", "orderedList", "unorderedList"],
      ["link", "unlink"]
    ]
  }

  constructor(private messages: MessageService, private error: ErrorService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadMessages();
  }

// TODO: IE11: ngx-editor is not supported for IE11
  loadMessages() {
    var ptr = this;
    ptr.meldungen = [];
    this.messages.getMessagesAsAdmin().subscribe(res => {
      res.forEach(function (e) {
        ptr.meldungen = [...ptr.meldungen, new Meldung(e.id, e.title, e.message, e.created, e.read, e.signatures)];
      });
      ptr.loaded = true;
      ptr.cd.detectChanges();
    });
  }

  send() {
    this.messages.add(this.caption, this.htmlContent).subscribe(res => {
      //this.router.navigate(['/']);
      this.error.set("Nachricht erfolgreich hinzugefügt", 'alert-success');
      var ptr = this;
      res.forEach(function (e) {
        ptr.meldungen = [new Meldung(e.id, e.title, e.message, e.created, e.read, e.signatures), ...ptr.meldungen];
      });
      ptr.cd.detectChanges();
    },
    (error: Response) => {
      this.error.set("Fehler beim Versenden der Nachricht");
    });
  }

  isLoaded() {
    return this.loaded;
  }
}
