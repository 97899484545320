import { Component, Inject, Optional, OnInit } from '@angular/core';
import { LoginService } from './services/login/login.service';
import { ErrorService } from './services/error/error.service';
import { ErrorComponent } from './error/error.component';
import { Router } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-AT';

import { map } from 'rxjs/operators';

@Component({
  selector: 'btf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'BTF Schichtplan';
  ref = null;

  constructor(
    public loginService: LoginService,
    public router: Router,
    public error: ErrorService) {
      registerLocaleData(localeDe, 'de-AT');
    }

  // TODO: Set title
  // TODO: Invalid login
  // TODO: JWT Auth token https://github.com/lcobucci/jwt/blob/3.2/README.md

  ngOnInit() {
    // TODO: Remove
    this.router.events
      .subscribe(event => {
        console.log(event);
      });
  }

  onActivate(ref) {
    this.ref = ref;
  }

  getMaxWidth() {
    if(this.ref == null || typeof this.ref.getMaxWidth  === 'undefined')
      return 1140;
    return this.ref.getMaxWidth();
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
    return false;
  }
}
