import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    constructor(private http: HttpClient, @Inject("API_BASE_URL") private api: String) {
    }

    getMessages() {
      return this.http.get<any[]>(this.api + "messages/list");
    }

    getMessagesAsAdmin() {
      return this.http.get<any[]>(this.api + "admin/messages/list");
    }

    sign(mid : number, password : String) {
        return Observable.create((observer: Observer<Object>) => {
          this.http.post(this.api + "messages/sign", {"messageId": mid, "password": password}, {responseType: 'text'}).subscribe(res => {
            observer.next(res);
            observer.complete();
          },
          (error: Response) => {
            observer.error(error);
          });
        });
    }

    add(caption : String, text : String) {
      return Observable.create((observer: Observer<Object>) => {
        this.http.post(this.api + "admin/messages/add", {"caption": caption, "text": text}).subscribe(res => {
          observer.next(res);
          observer.complete();
        },
        (error: Response) => {
          observer.error(error);
        });
      });
    }
}
